.welcome-container .title-1 {
  margin-bottom: 12px;
}

.welcome-container .copy-2 {
  width: 288px;
  text-align: center;
}

.welcome-container {
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
