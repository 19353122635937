.report-card-wrapper {
  display: flex;
  align-items: center;
}

.report-card-wrapper .ui.header {
  margin: 0;
}

.report-card-wrapper .view-actions {
  margin-left: auto !important;
}

.report-card-wrapper .basic-plan {
  background-color: #EAE9F8;
  color: #2C1EBE;
  border-color: #EAE9F8;
}

.report-card-wrapper .badge.basic-plan.high {
  background-color:  #FAEAF0;
  color: #CF2C67;
  border-color:  #FAEAF0;
}

.report-card-wrapper .badge.basic-plan.medium {
  background-color: #FFF7E7;
  color: #D49713;
  border-color: #FFF7E7;
}

.report-card-wrapper .badge.basic-plan.pending {
  background-color: #EAE9F8;
  color: #2C1EBE;
  border-color: #EAE9F8;
}
