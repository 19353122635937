.open-case-wrapper {
  height: 78%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.open-case-wrapper img {
  width: 45%;
  height: 30%;
}

@media only screen and (min-width: 600px) {
  .open-case-wrapper img {
    height: 20%;
  }
  .copy-2 {
    font-size: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .title-1 {
    font-size: 18px;
  }
  .copy-2 {
    font-size: 9px;
  }
}

@media only screen and (min-width: 842px) {
  .copy-2 {
    font-size: 8px;
  }
}

@media only screen and (min-width: 992px) {
  .open-case-wrapper img {
    height: 20%;
  }
  .copy-2 {
    font-size: 10px;
  }
}

@media only screen and (min-width: 1080px) {
  .open-case-wrapper img {
    height: 20%;
  }
  .copy-2 {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1200px) {
  .open-case-wrapper img {
    height: 20%;
  }
  .copy-2 {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1300px) {
  .open-case-wrapper img {
    height: 30%;
  }
  .copy-2 {
    font-size: 15px;
  }
}

.case-description {
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 12px 0 24px 0;
}

.case-description p {
  margin-top: 12px;
  white-space: pre-wrap;
  text-align: center;
}
