.login-dashboard-container {
  height: 100%;
  font-style: normal;
  background: #fafafa;
}

.login-dashboard-container .profile {
  height: 100%;
}

.login-dashboard-container .profile .kormoon-logo {
  padding: 0.8rem;
  position: fixed;
}

.login-dashboard-container .rightFrame {
  background: #fff;
  height: 100%;
}

.login-case-wrapper {
  height: 78%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-right-container {
  height: 100%;
  background-color: #fff;
}

.login-right-container img {
  width: 222px;
  margin: 24px;
}

.login-right-container .copy-1 {
  align-self: center;
  width: 500px;
}

.login-right-container .copy-2 {
  align-self: center;
  width: 440px;
}

.login-right-container .what-is-kormoon {
  margin-top: 24px;
}

.login-right-container .tagline {
  margin-top: 12px;
  margin-bottom: 24px;
}

.login-form-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form-wrapper > .title-1 {
  margin: 0.7rem;
  padding-bottom: 1rem;
}

.login-form-wrapper .login-links {
  display: flex;
  justify-content: space-between;
  margin: 0.7rem;
}

.login-form-wrapper .reset-password-button {
  cursor: pointer;
}

.notification-error {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  border-radius: 0;
  background-color: #CF2C67 !important;
  box-shadow: none !important;
}

.login-button{
  border-radius: 20px;
  background-color: red;
}