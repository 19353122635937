.modalWrapper {
  background: #ffffff;
  box-shadow: 0px 32px 56px rgba(0, 0, 0, 0.2) !important;
  border-radius: 20px !important;
  padding: 10px !important;
}

.modalActions {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 16px 20px;
}

.finish-button {
  padding: 0 16px !important;
}

.modalTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;

  color: #262626;
}
.modal-heading-description {
  margin-bottom: 32px;
}
.actionbtn {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 32px;
  color: #2C1EBE;
  padding: 5px;
  cursor: pointer;
}
.form-background {
  background: #fafafa !important;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 20px;
}
.form-background label {
  font-size: 13px !important;
}

.disabled-btn {
  background-color: #fff !important;
  color: #e5e5e5 !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  border: 1px solid #e5e5e5 !important;
}

.error-message {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  color: #CF2C67;
}

.split-field-wrapper {
  display: 'flex';
  flex-direction: 'row';
  justify-content: space-between;
}
.splited-field{
  width: 50%;
}