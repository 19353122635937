.case-details-wrapper {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.case-details-wrapper .ui.button.tertiary {
  align-self: flex-start;
}

#case-details-case-title {
  margin-top: -12px;
}

.case-details-description{
  margin-top: 15px;
}

.editText{
  cursor: pointer;
  color: #2C1EBE;
  font-weight: bold;
}