.account-actions-wrapper {
  align-items: center;
  font-weight: normal;
  color: #262626;
  padding: 10px;
}
.user-account {
  margin-top: 34px;
}
.account-actions-wrapper .account-actions .disabled {
  pointer-events: none;
}

.caption {
  font-size: 15px;
  line-height: 28px;
}

.company-form-wrapper .ui.button {
  margin-left: 10px;
  margin-top: 20px;
}
.success-message {
  color: #7dbb91;
}
.first-last-name {
  display: flex;
  align-items: stretch;
}
.first-last-name-input {
  flex-grow: 5;
}

.teamMember-card-container{
  margin-top: 12px;
}
.button-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 24px;
}
.tertiary {
  margin-top: 24px
}