.loader-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-message {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #262626;
  padding-top: 25px;
}

.loader-items {
  display: grid;
  grid-template-columns: 100% 1fr;
  grid-template-rows: 160px 1fr;
}

.loader-logo {
  grid-area: 1 / 1 / 2 / 2;
  align-self: center;
  justify-self: center;
  width: 40px;
  height: 40px;
}

.loader {
  grid-area: 1 / 1 / 2 / 2;
}
