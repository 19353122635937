.ui.modals .ui.modal.list-actions-modal {
  margin: 0;
  align-self: flex-end;
  width: 600px;
  overflow: hidden;
  height: 100%;
  border-radius: 0;
}

.list-actions-modal .list-actions-modal-content.content {
  padding: 0;
  border: 0;
  height: 100%;
  overflow: auto;
  background-color: #eeeeee;
}

.list-actions-modal-content .list-actions-wrapper {
  text-align: left;
  margin-top: 120px;
  border-radius: 0;
}

.list-actions-modal .close.icon {
  top: 8px;
  right: 8px;
}

.list-actions-modal .close.icon:before {
  content: "\2715";
}

.list-actions-modal-content .list-actions-wrapper .list-title {
  background-color: #2C1EBE;
  color: #fff;
  padding: 32px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.list-actions-modal-content .list-actions-wrapper .list-content {
  padding: 32px;
}

.list-content .report-notes {
  margin-bottom: 20px;
}

.list-content .report-notes > .title-2 {
  margin-bottom: 8px;
}

.list-content .list-action {
  margin-bottom: 32px;
}

.list-content .list-action .tooltip-icon {
  margin-left: 8px;
  margin-bottom: -1px;
}

.list-content .list-actions .title-2 {
  margin-bottom: 0px;
}

.list-content .action-item .title-3 {
  margin-bottom: 4px;
}

.list-content .action-item .status {
  color: #7DBB91;
  margin-left: 8px;
}

.list-content .action-item .checkbox label {
  z-index: 0;
}

.list-content .action-item {
  border-radius: 6px;
  padding: 20px;
  margin-top: 12px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 6px;
}

.list-content .action-item .actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
