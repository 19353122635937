.input-wrapper {
  padding: 8px;
}

.label-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 8px;
}

.input-wrapper .delete.icon {
  opacity: 1;
}

.input-wrapper .delete.icon:before {
  content: "\2715";
}

.label-wrapper .label-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-wrapper .label-left .title-3 {
  padding-right: 8px;
}

.label-wrapper .label-right {
  text-align: right;
}

select option[data-default] {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  color: "#ccc";
}

.tooltip {
  background-color: #0D1D3B !important;
  color: #fff !important;
}

.tooltip:before {
  background-color: #0D1D3B !important;
}

.tooltip ul {
  padding-left: 24px;
}

.input-wrapper .ui.multiple.dropdown input {
  display: none;
}

.multi-select > .label {
  font-size: 13px !important;
  font-weight: 600;
  color: #2C1EBE;
  margin: 6px !important;
  padding: 10px !important;
  background-color: #EAE9F8;
  border-radius: 20px;
}

.ui.multiple.dropdown > .label{
  box-shadow: 0px 0px 0px 0px;

}

.multi-select > .label:hover {
  background-color: #4f4d64 !important;
}

.multi-select .text,
.multi-select .ui.search.dropdown > input.search,
.multi-select .ui.search.dropdown > input.search::placeholder {
  font-size: 15px;
  line-height: 15px;
}

.multi-select {
  min-height: 44px !important;
}

.custom-input {
  padding: 10px 10px 10px 14px !important;
}
.custom-input > .label {
  padding: 10px !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  opacity: 0.4;
}

.ui.form input[type="text"]:focus {
  border-color: #c3c3c3 !important;
}