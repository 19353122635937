.view-plan-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 68.75%;
  background: #ffffff;
  box-shadow: 0px -1px 24px rgba(38, 38, 38, 0.05);
  padding: 2%;
}

.view-plan-wrapper .content {
  display: flex;
  justify-content: space-between;
}

.view-plan-wrapper .content p {
  margin-top: 8px;
}

.pro-badge {
  color: #fff;
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
  width: 75px;
  height: 10px;
  background: #383747;
  border-radius: 100px;
  padding: 3px 13px;
  margin-left: 10px;
}
