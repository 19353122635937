.card.outer-box {
  margin-top: 15px;
  min-height: 80px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 3px;
}

.card .inner-box {
  height: 100%;
  padding: 15px;
  border-left: 7px solid transparent;
}

.card .inner-box.active {
  border: 2px solid #2C1EBE;
  border-radius: 8px;
}

.card .inner-box.account_active {
  border-left: 7px solid #383747;
  border-radius: 3px 0px 0px 3px;
}
.card .inner-box.inactive {
  color: #ccc;
  border-left: 1px solid #e5e5e5;
  cursor: not-allowed;
}
