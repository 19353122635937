.actions-wrapper {
  align-items: center;
  font-weight: normal;
  color: #262626;
  padding: 10px;
}

.actions-wrapper .case-title {
  margin-bottom: 32px;
}

.actions-wrapper .report-actions .disabled {
  pointer-events: none;
}

.caption {
  font-size: 15px;
  line-height: 28px;
}

.case-file {
  padding-top: 30px;
}
