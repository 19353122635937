.get-started {
  width: 500px;
  height: 150px;
  left: 669px;
  top: 556px;

  margin-bottom: 2rem;
  text-align: center;
  flex: none;
  align-self: center;
}

.GS-image{
  width: 240px;
}
