

.sidebar-icons .secondary path {
  fill: #2C1EBE;
}
.sidebar-icons .secondary {
  color: #ffffff;
}

.sidebar-icons .primary path {
  fill: #2C1EBE;
}

.dashboardContainer {
  height: 100%;
  font-style: normal;
}

.sidebarContainer {
  height: 100%;
  background: #fff;
  border-right: 1px solid #EAEAEA;
}

.sidebar-icons {
  height: 90%;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;

}

.icon-item {
  margin-top: 35px;
}

.logo {
  padding-top: 30%;
  height: 5%;
}

.logo path {
  fill: #2C1EBE;
}

.profile {
  height: 100%;
  width: 100%;
}

.left-frame {
  border-right: 1px solid rgba(38, 38, 38, 0.1);
}

.rightFrame {
  background: #fafafa;
  height: 100%;
}

.ui.dropdown .menu {
  border: none;
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  color: #fff;
}

.ui.dropdown .menu > .item {
  color: #fff;
}

.ui.dropdown .menu > .item:hover {
  color: #fff;
}

.ui.menu .ui.dropdown .menu > .item:hover {
  color: #fff !important;
}

.ui.dropdown > .dropdown.icon {
  font-family: Icons;
}

.ui.dropdown > .dropdown.icon:before {
  content: "\f078";
}

@media screen and (max-width: 767px) {
  .dashboardContainer2 {
    display: flex;
    margin: 0 0 !important;
    padding: 1rem 1rem !important;
    flex-direction: row;
    height: 100vh !important;
    width: 100% !important;
    box-shadow: none !important;
  }
  .getStarted {
    width: 100% !important;
    height: 150px !important;
    left: 0 !important;
    top: 0 !important;
    font-style: normal;
    font-weight: 0 !important;
    font-size: 12px !important;
    line-height: none !important;
    margin-bottom: none !important;
    text-align: center;
    color: #262626;
    flex: none;
    align-self: center;
  }
  .welcome {
    font-size: 12px !important;
  }
  .welcome h2 {
    margin-bottom: 0 !important;
  }
  .profileHeader {
    height: 25% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .welcomeBody {
    height: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sidebar-icons {
    height: 0 !important;
    display: flex;
    justify-content: space-around !important;
    flex-direction: row !important;
    color: #fff !important;
  }
  .logo {
    padding-top: 0 !important;
    height: 50% !important;
  }
  .ui.compact.menu {
    font-size: x-small !important;
  }
  .ui.segment {
    display: flex !important;
    align-items: flex-start !important;
  }
  .case-card {
    display: flex !important;
    flex-direction: column-reverse !important;
    align-items: flex-start !important;
  }
  .case-file-p {
    font-size: 14px !important;
    margin-bottom: 0.9rem !important;
  }
  .ui[class*="right floated"].header {
    align-self: flex-end !important;
  }
  .dashboardContainer {
    height: 30% !important;
  }
}
