.modalWrapper.reset-password-modal {
  width: 600px;
  height: 572px;
}

.modalWrapper .close.icon {
  top: 8px;
  right: 8px;
  color: #000;
}

.modalWrapper .close.icon:before {
  content: "\2715";
}

.reset-password-wrapper {
  height: 78%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reset-password-wrapper img {
  padding-top: 10%;
  width: 120px;
}

.reset-password-description {
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 12px 0 24px 0;
}

.reset-password-description p {
  margin-top: 12px;
  white-space: pre-wrap;
  text-align: center;
}
.reset-password-wrapper .form-item {
  width: 320px;
}
.reset-password-wrapper .form-item.reset-password-btn {
  margin-right: 0px;
}
