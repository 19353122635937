.not-available-container {
  height: 100%;
}

.not-available-container img {
  width: 222px;
  margin: 24px;
}

.not-available-container .copy-1 {
  align-self: center;
  width: 440px;
}
.not-available-container .copy-2 {
  align-self: center;
  width: 440px;
}
