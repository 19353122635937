.button-and-select {
  display: flex;
  flex-direction: row;
  margin-top: 1rem !important;
}

.button-and-select .ui.button.tertiary.action {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.casefile-dropdown .ui.button.tertiary.dropdown {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.casefile-dropdown .icon {
  margin-right: 0 !important;
}

.casefile-dropdown.dropdown {
  display: flex;
}

.casefile-dropdown.dropdown > .menu {
  width: 200px;
  margin-top: 12px;
  background: #0D1D3B;
  border-radius: 6px;
}

.casefile-dropdown.upward.dropdown > .menu {
  margin-top: 0;
  margin-bottom: 12px;
}

.casefile-dropdown.dropdown > .menu .text {
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
}

.label {
  margin-top: .9rem !important;
  margin-right: .5rem !important;
}

.case-card {
  padding: .8rem !important;
}