.case-actions-container {
  height: 78%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.case-actions-container img {
  width: 120px;
}

.case-actions-instructions {
  width: 100%;
  display: flex;
  align-items:center;
  flex-direction: column;
  font-size: 1.1rem;
  margin: 3rem 0 2rem 0;
  text-align: center;
}

.case-actions-instructions p {
  width: 288px;
  margin-top: 12px;
}

.case-actions-instructions h3 {
  font-size: 22px;
}
