.member-card {
  padding: .8rem !important;
}
.profile-avatar {
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border: 1px solid #CCCCCC;
  border-radius: 50%;
  text-transform: uppercase;
}
.member-role{
  margin-top: 4px;
  font-size: 13px;
  font-weight: normal;
  color: #888686;
}
.button-and-select {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem !important;
}