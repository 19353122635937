.modalWrapper-delete {
  width: 600px !important; 
  height: 308px !important;
  background: #ffffff;
  box-shadow: 0px 32px 56px rgba(0, 0, 0, 0.2) !important;
  border-radius: 20px !important;
  padding: 10px !important;
}

.delete-modal-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.delete-modal-content {
  margin-top: 47px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.delete-modal-content .title-1 {
  margin-bottom: 12px;
}

.icon-close {
  position: absolute;
  right: 0;
  margin-right: 23px;
  cursor: pointer;
}

.delete-modal-content .ui.button {
  background-color: #E47575;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 32px;
  border-radius: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 32px;
}

.ui.button:hover {
  background-color: #e47575e0;
  color: #fff;
}

.delete-warning-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  height: 48px;
}

.delete-warning-text {
  margin: 0em 0em 0em !important;
}
