.delete-confirmation-modal {
  width: 600px !important;
}

.delete-confirmation-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.delete-confirmation-wrapper .title-1 {
  margin-bottom: 12px;
}

.delete-confirmation-wrapper .copy-2 {
  text-align: center;
  margin-bottom: 24px;
}

.delete-confirmation-wrapper button {
  width: 240px;
  margin-bottom: 12px !important;
}

.delete-confirmation-wrapper img {
  margin-bottom: 24px;
}
