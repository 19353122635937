.navigation-wrapper {
  height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-dropdown > .dropdown.icon {
  font-family: Dropdown;
  line-height: 1;
  height: 1em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}

.dashboard-dropdown.dropdown > .menu {
  background: #0D1D3B !important;
  position: absolute !important;
  width: 200px !important;
  padding: 9px 7px !important;
  
}

.dashboard-dropdown.dropdown > .menu > .item {
  padding: 7px !important;
}

.dashboard-dropdown:hover .profile-avatar {
  border-color: #383747;
}

.navigation-wrapper .profile-avatar {
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: 1px solid #CCCCCC;
  border-radius: 50%;
  margin: 12px 0;
  text-transform: uppercase;
}
