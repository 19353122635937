.create-account-wrapper {
  background: #ffffff;
  box-shadow: 0px 32px 56px rgba(0, 0, 0, 0.2) !important;
  border-radius: 20px !important;
  padding: 10px !important;
  width: 100px;
}
.split-field-wrapper {
  display: flex;
  justify-content: space-between;
}

.checkbox-wrapper {
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.checkbox-wrapper .checkbox {
  margin-right: 8px;
}

.modalWrapper.create-account-get-started-modal {
  width: 600px;
  height: 572px;
}

.modalWrapper .close.icon {
  top: 8px;
  right: 8px;
  color: #000;
}

.modalWrapper .close.icon:before {
  content: "\2715";
}

.create-account-get-started-wrapper {
  height: 78%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.create-account-get-started-wrapper img {
  width: 223px;
  height: 200px;
  margin-bottom: 24px;
}

.create-account-get-started-title {
  margin-bottom: 12px;
}
.create-account-get-started-description {
  width: 440px;
  height: 72px;
  white-space: pre-wrap;
  text-align: center;
}

.auth0-error {
  margin-bottom: 10px;
}

.create-account-get-started-wrapper button {
  margin-top: 24px;
}
